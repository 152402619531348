//Slider home
#home-hero--slider {
	.item {
		height: 478px;
		overflow: hidden;
		img {
			height: 100%;
			width: auto; } }
	.item.active {
		//display: flex
		img {
			object-fit: cover;
			width: 100%; } }
	.btn {
		margin-right: 15px; }
	.carousel-caption {
		text-align: center;
		padding-top: 0;
		padding-bottom: 0;
		text-shadow: none;
		top: 50%;
		transform: translateY(-50%);
		h2 {
			font-weight: 700;
			margin-top: 0;
			margin-bottom: $m-b-sm; }
		h4 {
			color: #fff;
			font-weight: 300;
			margin-top: 0;
			margin-bottom: $m-b-md; } }
	.carousel-control {
		font-size: 24px;
		color: #fff;
		text-shadow: none;
		background-color: transparent;
		filter: alpha(opacity=50);
		opacity: .5;
		transition: all .3s ease;
		.icon-prev, .icon-next, .glyphicon-chevron-left, .glyphicon-chevron-right {
			margin-top: -20px; }
		.icon-prev, .glyphicon-chevron-left {
			margin-left: -12px; }
		.icon-next, .glyphicon-chevron-right {
			margin-right: -12px; } }
	.carousel-control:focus, .carousel-control:hover {
		color: #fff;
		text-decoration: none;
		filter: alpha(opacity=100);
		outline: 0;
		opacity: 1; }
	.carousel-control.left, .carousel-control.right {
		background-image: none;
		background: transparent; } }
//flexslider
.flex-direction-nav {
	a {
		height: 50px !important;
		top: 45% !important; } }
//Productos destacados home
.home--featured-products--wrap {
	.flexslider {
		background-color: transparent;
		margin: 30px 0;
		border: none;
		box-shadow: none; } }
.home--featured-products-slider {
	div[class*="col-"] {
		@include m-b-md;
		&:hover .featured-products--slider--add-cart {
			opacity: 1; } }
	.products-list--item {
		display: block;
		background-color: #fff;
		border-radius: 4px;
		height: 280px;
		line-height: normal;
		position: relative;
		padding: 15px;
		transition: all .15s ease-in-out;
		overflow: hidden;
		box-shadow: 1px 1px 1px rgba(0,0,0, .1);
		&:hover, &:focus {
			text-decoration: none;
			box-shadow: 0 0 9px rgba(0,0,0, .2); }
		.featured-products--slider--img {
			display: block;
			height: 40%;
			margin-bottom: $m-b-sm;
			overflow: hidden;
			position: relative;
			img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				object-fit: contain;
				width: 100%;
				height: 100%; } }
		.featured-products--slider--tools {
			position: absolute;
			display: inline-block;
			top: 15px;
			right: 15px; }
		small {
			display: block;
			color: $clr-gris-azul;
			font-size: 10px;
			font-weight: 300;
			text-transform: uppercase;
			margin-bottom: $m-b-xs; }
		h2 {
			color: $clr-oxford;
			font-size: 16px;
			font-weight: 700;
			line-height: 1.3;
			margin-top: 0;
			margin-bottom: $m-b-xs;
			a {
				color: $clr-oxford; } }
		h3 {
			color: $clr-gris-azul;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.3;
			margin-top: 0;
			margin-bottom: $m-b-xs; }
		p {
			color: $clr-oxford;
			font-size: 14px;
			font-weight: 700;
			margin-top: 0; }
		.products-list--item--old-price {
			color: $clr-gris-azul;
			text-decoration: line-through;
			margin-bottom: 0;
			font-weight: 300; }
		.products-list--item--price {
			color: $clr-rojo; } }
	.featured-products--slider--add-cart {
		position: absolute;
		bottom: -31px;
		left: 15px;
		width: calc(100% - 30px);
		opacity: 0;
		transition: opacity .2s ease;
		z-index: 30;
		.btn-amarillo {
			border-top-left-radius: 0;
			border-top-right-radius: 0; }
		.btn-default {
			border: 1px solid $clr-gris-borde; }
		&:hover {
			opacity: 1; } } }
//Promos Cart
.cart--promos--wrap {
	.flexslider {
		background-color: transparent;
		margin: 0 0 30px 0;
		border: none;
		box-shadow: none; } }
.cart--promos--slider {
	div[class*="col-"] {
		@include m-b-md;
		&:hover .cart--promos--slider--add-cart {
			opacity: 1; } }
	.products-list--item {
		display: block;
		background-color: #fff;
		height: 110px;
		line-height: normal;
		position: relative;
		padding: 0;
		transition: all .15s ease-in-out;
		overflow: hidden;
		box-shadow: none;
		&:hover, &:focus {
			text-decoration: none; }
		.cart--promos--slider--img {
			float: left;
			border: 1px solid $clr-gris-borde;
			border-radius: 4px;
			height: 90px;
			width: 35%;
			margin-right: 10px;
			overflow: hidden;
			position: relative;
			img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				object-fit: contain;
				width: 100%;
				height: 100%; } }
		.cart--promos--slider--info {
			float: left;
			width: 60%;
			small {
				display: block;
				color: $clr-gris-azul;
				font-size: 10px;
				font-weight: 300;
				text-transform: uppercase;
				margin-bottom: 5px; }
			h2 {
				color: $clr-oxford;
				font-size: 13px;
				font-weight: 700;
				line-height: 1;
				margin-top: 0;
				margin-bottom: 5px;
				a {
					color: $clr-oxford; } }
			h3 {
				color: $clr-gris-azul;
				font-size: 11px;
				font-weight: 300;
				line-height: 1;
				margin-top: 0;
				margin-bottom: 5px; }
			.cart--promos--prices {
				margin-bottom: 5px;
				font-size: 12px;
				.cart--promos--old-price {
					color: $clr-gris-azul;
					text-decoration: line-through;
					font-weight: 300; }
				.cart--promos--price {
					color: $clr-rojo; } } } }
	.cart--promos--slider--add-cart {
		.btn-default {
			border: 1px solid $clr-gris-borde; } } }
//Product detail
.products--wrap {
	#carousel.flexslider {
		background-color: transparent;
		border: none;
		.slides {
			li {
				background-color: #fff;
				border: 1px solid $clr-gris-claro;
				border-radius: 4px;
				height: 120px;
				overflow: hidden;
				position: relative;
				text-align: center;
				img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					object-fit: contain;
					width: 100%;
					height: 100%; } } } }
	.flexslider {
		margin-bottom: 15px; }
	#slider {
		border: 1px solid $clr-gris-claro;
		.slides {
			li {
				height: 500px;
				overflow: hidden;
				position: relative;
				text-align: center;
				img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					object-fit: contain;
					width: 100%;
					height: 100%; } } } } }
@media (max-width: 768px) {
	#home-hero--slider {
		.item {
			height: 300px;
			img {
				height: auto;
				min-width: 100%;
				min-height: 100%; } }
		.btn {
			margin-right: 0;
			padding: 4px 10px;
			font-size: 13px; }
		.btn-blanco-outline {
			display: block;
			border: none;
			box-shadow: none;
			padding: 0;
			font-size: 13px;
			@include m-t-sm;
			&:hover, &:focus {
				background-color: transparent;
				color: #fff;
				text-decoration: underline; } }
		.carousel-control {
			font-size: 18px; }
		.carousel-caption {
			bottom: 0;
			h2 {
				font-size: 18px; }
			h4 {
				font-size: 14px;
				margin-bottom: $m-b-sm; } }
		.carousel-indicators {
			bottom: 0; } }
	.home--featured-products-slider {
		a {
			height: auto;
			margin-bottom: $m-b-sm;
			.featured-products--slider--img {
				height: 120px; } } }
	#slider {
		.slides {
			li {
				height: 250px !important; } } } }
