.sb-social {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s ease {
            transition: all 0.4s ease; } }

.sb-social.sb-center.sb-top {
    top: 0;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%); }
.sb-social.sb-center.sb-bottom {
    bottom: 0;
    -webkit-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%); }

.sb-social.sb-center.active.sb-top {
    top: -1em; }
.sb-social.sb-center.active.sb-bottom {
    bottom: -1em; }
.sb-social.sb-left {
    left: 50%; }
.sb-social.sb-left.sb-top {
    top: 0;
    -webkit-transform: translate(calc(-100% + 30px), -100%) {
        -ms-transform: translate(calc(-100% + 30px), -100%) {
            transform: translate(calc(-100% + 30px), -100%); } } }
.sb-social.sb-left.sb-middle {
    top: 50%;
    left: 0;
    -webkit-transform: translate(-100%, -50%) {
        -ms-transform: translate(-100%, -50%) {
            transform: translate(-100%, -50%); } } }
.sb-social.sb-left.sb-bottom {
    bottom: 0;
    -webkit-transform: translate(calc(-100% + 30px), 100%) {
        -ms-transform: translate(calc(-100% + 30px), 100%) {
            transform: translate(calc(-100% + 30px), 100%); } } }
.sb-social.sb-left.active.sb-top {
    top: -1em; }
.sb-social.sb-left.active.sb-middle {
    left: -1em; }
.sb-social.sb-left.active.sb-bottom {
    bottom: -1em; }
.sb-social.sb-right {
    left: 50%; }
.sb-social.sb-right.sb-top {
    top: 0;
    -webkit-transform: translate(-30px, -100%) {
        -ms-transform: translate(-30px, -100%) {
            transform: translate(-30px, -100%); } } }
.sb-social.sb-right.sb-middle {
    top: 50%;
    left: 100%;
    -webkit-transform: translate(0, -50%) {
        -ms-transform: translate(0, -50%) {
            transform: translate(0, -50%); } } }
.sb-social.sb-right.sb-bottom {
    bottom: 0;
    -webkit-transform: translate(-30px, 100%) {
        -ms-transform: translate(-30px, 100%) {
            transform: translate(-30px, 100%); } } }
.sb-social.sb-right.active.sb-top {
    top: -1em; }
.sb-social.sb-right.active.sb-middle {
    left: calc(100% + 1em); }
.sb-social.sb-right.active.sb-bottom {
    bottom: -1em; }
.sb-social.active {
    opacity: 1;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    visibility: visible; }
.sb-social.load {
    -webkit-transition: none !important;
    transition: none !important; }
@media screen and (max-width: 400px) {
    .sb-social.networks-6.sb-center {
        white-space: initial;
        text-align: center;
        width: 300px; } }
@media screen and (max-width: 460px) {
    .sb-social.networks-7.sb-center {
        white-space: initial;
        text-align: center;
        width: 360px; } }
@media screen and (max-width: 400px) {
    .sb-social.networks-7.sb-center {
        white-space: initial;
        text-align: center;
        width: 300px; } }
@media screen and (max-width: 520px) {
    .sb-social.networks-8.sb-center {
        white-space: initial;
        text-align: center;
        width: 420px; } }
@media screen and (max-width: 460px) {
    .sb-social.networks-8.sb-center {
        white-space: initial;
        text-align: center;
        width: 360px; } }
@media screen and (max-width: 400px) {
    .sb-social.networks-8.sb-center {
        white-space: initial;
        text-align: center;
        width: 300px; } }
.sb-social ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 0; }
.sb-social ul li {
    position: relative;
    height: 22px;
    width: 60px;
    padding: 12px 0;
    margin: -2px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    box-sizing: content-box;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
.sb-social ul li.enabled {
    display: inline-block; }
.sb-social ul li.disabled {
    display: none; }
.sb-social ul li:hover:before {
    opacity: 0; }
.sb-social ul li:hover:after {
    opacity: 0.5; }
.sb-social ul li:before,
.sb-social ul li:after {
    content: ' ';
    position: absolute;
    width: inherit;
    height: inherit;
    -webkit-transform: translate(-20%, 0);
    -ms-transform: translate(-20%, 0);
    transform: translate(-20%, 0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background-repeat: no-repeat !important; }
.sb-social ul li:before {
    opacity: 1; }
.sb-social ul li:after {
    opacity: 0; }
.sb-social ul li a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }
.sb-social li[class*='facebook'] {
    background: #3b5998; }
.sb-social li[class*='facebook']:before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20style%3D%22fill%3A%23fff%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M288%20192v-38.1c0-17.2%203.8-25.9%2030.5-25.9H352V64h-55.9c-68.5%200-91.1%2031.4-91.1%2085.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z%22%2F%3E%3C%2Fsvg%3E"); }
.sb-social li[class*='facebook']:after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20style%3D%22fill%3A%23000%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M288%20192v-38.1c0-17.2%203.8-25.9%2030.5-25.9H352V64h-55.9c-68.5%200-91.1%2031.4-91.1%2085.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z%22%2F%3E%3C%2Fsvg%3E"); }
.sb-social li[class*='twitter'] {
    background: #6cdfea; }
.sb-social li[class*='twitter']:before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20style%3D%22fill%3A%23fff%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M17.316%206.246c.008.162.01.326.01.488%200%204.99-3.796%2010.742-10.74%2010.742-2.132%200-4.115-.625-5.786-1.697.296.033.596.05.9.05%201.77%200%203.397-.603%204.688-1.614-1.65-.03-3.046-1.12-3.526-2.62.23.042.467.065.71.065.345%200%20.68-.044.995-.13C2.84%2011.18%201.54%209.658%201.54%207.828V7.78c.508.284%201.09.454%201.71.474-1.014-.678-1.68-1.832-1.68-3.143%200-.69.185-1.34.51-1.896C3.943%205.498%206.726%207%209.863%207.158c-.064-.277-.097-.564-.097-.86%200-2.085%201.69-3.774%203.774-3.774%201.085%200%202.066.457%202.755%201.19.86-.17%201.667-.483%202.397-.915-.282.88-.88%201.62-1.66%202.086.764-.092%201.49-.293%202.168-.594-.506.758-1.146%201.422-1.884%201.953z%22%2F%3E%3C%2Fsvg%3E"); }
.sb-social li[class*='twitter']:after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20style%3D%22fill%3A%23000%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M17.316%206.246c.008.162.01.326.01.488%200%204.99-3.796%2010.742-10.74%2010.742-2.132%200-4.115-.625-5.786-1.697.296.033.596.05.9.05%201.77%200%203.397-.603%204.688-1.614-1.65-.03-3.046-1.12-3.526-2.62.23.042.467.065.71.065.345%200%20.68-.044.995-.13C2.84%2011.18%201.54%209.658%201.54%207.828V7.78c.508.284%201.09.454%201.71.474-1.014-.678-1.68-1.832-1.68-3.143%200-.69.185-1.34.51-1.896C3.943%205.498%206.726%207%209.863%207.158c-.064-.277-.097-.564-.097-.86%200-2.085%201.69-3.774%203.774-3.774%201.085%200%202.066.457%202.755%201.19.86-.17%201.667-.483%202.397-.915-.282.88-.88%201.62-1.66%202.086.764-.092%201.49-.293%202.168-.594-.506.758-1.146%201.422-1.884%201.953z%22%2F%3E%3C%2Fsvg%3E"); }
.sb-social li[class*='whatsapp'] {
  background: #4dc247; }
/*  &:before*/
.sb-social li[class*='whatsapp']:before {
    background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2087%2088%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20style%3D%22fill%3A%23fff%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M61.623%2051.83c-.94-.515-5.562-3.01-6.428-3.363-.867-.35-1.5-.535-2.173.4-.675.934-2.593%203.023-3.175%203.642-.583.615-1.144.672-2.084.16-.94-.516-3.99-1.646-7.525-5.045-2.752-2.645-4.557-5.854-5.08-6.834-.526-.977-.005-1.477.493-1.936.45-.413%201-1.084%201.5-1.625.5-.54.678-.934%201.02-1.563.343-.625.204-1.19-.017-1.678-.222-.49-1.962-5.276-2.687-7.223-.725-1.946-1.537-1.657-2.095-1.678-.557-.023-1.194-.126-1.833-.15-.637-.024-1.684.176-2.6%201.104-.912.922-3.474%203.147-3.65%207.862-.174%204.713%203.084%209.395%203.538%2010.05.456.658%206.23%2010.875%2015.823%2015.075%209.596%204.2%209.645%202.92%2011.406%202.826%201.763-.09%205.756-2.104%206.638-4.31.884-2.205.955-4.125.73-4.532-.225-.408-.855-.673-1.794-1.188m-17.6%2022.714c-6.345%200-12.253-1.903-17.187-5.163L14.84%2073.22l3.902-11.6C15%2056.466%2012.79%2050.137%2012.79%2043.3c0-17.226%2014.014-31.24%2031.24-31.24%2017.224%200%2031.24%2014.012%2031.24%2031.24%200%2017.226-14.016%2031.24-31.24%2031.24M6.5%2043.3c0%207.09%201.968%2013.72%205.384%2019.38L5.11%2082.808l20.78-6.65c5.375%202.972%2011.558%204.667%2018.137%204.667%2020.725%200%2037.528-16.802%2037.528-37.525%200-20.727-16.804-37.528-37.53-37.528-20.723%200-37.523%2016.8-37.523%2037.527z%22%2F%3E%3C%2Fsvg%3E"); }
/*  &:after*/
.sb-social li[class*='whatsapp']:after {
    background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2087%2088%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20style%3D%22fill%3A%23000%3B%22%20height%3D%22%22%20width%3D%22%22%3E%20%3Cpath%20d%3D%22M61.623%2051.83c-.94-.515-5.562-3.01-6.428-3.363-.867-.35-1.5-.535-2.173.4-.675.934-2.593%203.023-3.175%203.642-.583.615-1.144.672-2.084.16-.94-.516-3.99-1.646-7.525-5.045-2.752-2.645-4.557-5.854-5.08-6.834-.526-.977-.005-1.477.493-1.936.45-.413%201-1.084%201.5-1.625.5-.54.678-.934%201.02-1.563.343-.625.204-1.19-.017-1.678-.222-.49-1.962-5.276-2.687-7.223-.725-1.946-1.537-1.657-2.095-1.678-.557-.023-1.194-.126-1.833-.15-.637-.024-1.684.176-2.6%201.104-.912.922-3.474%203.147-3.65%207.862-.174%204.713%203.084%209.395%203.538%2010.05.456.658%206.23%2010.875%2015.823%2015.075%209.596%204.2%209.645%202.92%2011.406%202.826%201.763-.09%205.756-2.104%206.638-4.31.884-2.205.955-4.125.73-4.532-.225-.408-.855-.673-1.794-1.188m-17.6%2022.714c-6.345%200-12.253-1.903-17.187-5.163L14.84%2073.22l3.902-11.6C15%2056.466%2012.79%2050.137%2012.79%2043.3c0-17.226%2014.014-31.24%2031.24-31.24%2017.224%200%2031.24%2014.012%2031.24%2031.24%200%2017.226-14.016%2031.24-31.24%2031.24M6.5%2043.3c0%207.09%201.968%2013.72%205.384%2019.38L5.11%2082.808l20.78-6.65c5.375%202.972%2011.558%204.667%2018.137%204.667%2020.725%200%2037.528-16.802%2037.528-37.525%200-20.727-16.804-37.528-37.53-37.528-20.723%200-37.523%2016.8-37.523%2037.527z%22%2F%3E%3C%2Fsvg%3E"); }
