.fade-in{
    
}
.fade-in.ng-hide-remove{
    animation: 0.3s fade-in;
    display: inline-block;
}
.fade-in.ng-hide-add{
    animation: 0.3s fade-out;
    display: inline-block;
}
@keyframes fade-in {
    from {
        opacity:0;
        height:0
    }
    to {
        opacity:1;
        height: auto; 
        width:100%;
    }
}
@keyframes fade-out {
    from {
        
        opacity:1;
    }
    to {
        height: 0px; 
        opacity:0;
    }
}