
// Body
$body-bg: 						#f5f8fa;

// Borders
$laravel-border-color: 			darken($body-bg, 10%);
$list-group-border: 			$laravel-border-color;
$navbar-default-border: 		$laravel-border-color;
$panel-default-border: 			$laravel-border-color;
$panel-inner-border: 			$laravel-border-color;

// Brands
$brand-primary: 				#F7971B;
$brand-info: 					#0e6ba4;
$brand-success: 				#00b20f;
$brand-warning: 				#FFD527;
$brand-danger: 					#FF5027;

// Typography
$font-family-sans-serif: 		"Lato", sans-serif;
$font-size-base: 				14px;
$line-height-base: 				1.6;
$text-color: 					#636b6f;

// Navbar
$navbar-default-bg: 			#fff;

// Buttons
$btn-default-color: 			$text-color;

// Inputs
$input-border: 					lighten($text-color, 40%);
$input-border-focus: 			lighten($brand-primary, 25%);
$input-color-placeholder: 		lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: 		#fff;

$clr-verde: 					#3db39e;
$clr-azul: 						#1286c6;
$clr-rojo: 						#c3250f;
$clr-naranja: 					#F7971B;
$clr-amarillo: 					#f7c200;
$clr-oxford:					#656565;
$clr-gris-azul:					#969ea2;
$clr-gris: 						#3c3c3b;
$clr-gris-claro: 				#e5e5e5;
$clr-gris-borde:				#ccd0d2;

//Margins
@mixin m-t-xs {margin-top:		7px};
@mixin m-t-sm {margin-top:		15px};
@mixin m-t-md {margin-top:		30px};
@mixin m-t-lg {margin-top:		60px};

@mixin m-b-xs {margin-bottom:	7px};
@mixin m-b-sm {margin-bottom:	15px};
@mixin m-b-md {margin-bottom:	30px};
@mixin m-b-lg {margin-bottom:	60px};

$m-t-sm:						15px;
$m-t-md:						30px;
$m-t-lg:						60px;

$m-b-xs:						5px;
$m-b-sm:						15px;
$m-b-md:						30px;
$m-b-lg:						60px;

$m-r-xs:						7px;
$m-r-sm:						15px;
$m-r-md:						30px;
$m-r-lg:						60px;

//Paddings
@mixin p-t-xs {padding-top:		7px};
@mixin p-t-sm {padding-top:		15px};
@mixin p-t-md {padding-top:		30px};
@mixin p-t-lg {padding-top:		60px};
@mixin p-t-xlg {padding-top:	120px};

$p-b-xs:						7px;
$p-b-sm:						15px;
$p-b-md:						30px;
$p-b-lg:						60px;
$p-b-xlg:						90px;

@mixin p-b-xs {padding-bottom:	7px};
@mixin p-b-sm {padding-bottom:	15px};
@mixin p-b-md {padding-bottom:	30px};
@mixin p-b-lg {padding-bottom:	60px};
@mixin p-b-xlg {padding-bottom:	120px};

$p-t-xs:						7px;
$p-t-sm:						15px;
$p-t-md:						30px;
$p-t-lg:						60px;
$p-t-xlg:						90px;
