footer {
	background: $clr-gris;
	padding-top: $p-t-lg;
	padding-bottom: $p-b-md;
	font-size: 16px; }
.footer-cintillo {
	background-color: $clr-rojo;
	@include p-t-sm;
	@include p-b-sm; }
.footer-superior {
	display: flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	padding-bottom: $p-b-lg;
	border-bottom: 1px solid rgba(255,255,255, .1);
	color: transparentize(#fff, .2);
	font-weight: 300;
	ul {
		list-style: none;
		margin: 0;
		padding: 0; }
	li {
		line-height: 1.6;
		color: transparentize(#fff, .1);
		font-weight: 300;
		margin-bottom: $m-b-sm;
		i {
			color: $clr-amarillo;
			margin-right: 5px; } }
	li>a {
		color: transparentize(#fff, .1); }
	.footer--social-item {
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			img {
				height: 32px;
				margin-right: 5px; } } }
	.input-group {
		.btn-primary {
			background-color: $clr-amarillo;
			color: #333; } } }
.titulo-footer {
	font-size: 18px;
	color: rgba(255,255,255, .5);
	padding-bottom: $m-b-sm; }
.footer-inferior {
	color: rgba(255,255,255, .5);
	font-weight: 300;
	padding-top: $p-t-md;
	a {
		color: #fff; }
	p {
		margin: 0; }
	[class|=col] {
		&:nth-child(3) {
			text-align: right; } } }
@media (max-width: 1200px) {
	.footer-superior {
		.footer-col {
			margin-left: auto;
			margin-right: auto;
			.titulo-footer {
				font-size: 14px;
				margin: 0;
				padding: 0;
				border: none; }
			ul {
				li>a {
					font-size: 12px;
					line-height: 1.5; } } } } }
@media (max-width: 1000px) {
	.footer-inferior {
		p {
			margin: 0 0 15px 0; }
		[class|=col] {
			text-align: center !important; } } }
@media (max-width: 767px) {
	footer {
		padding: 30px 0; }
	.footer-superior {
		display: none; }
	.footer-inferior {
		padding-top: 0;
		p {
			&:last-child {
				margin-bottom: 0; } } } }
