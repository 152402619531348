@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto-Bold"), url("Roboto-Bold.woff") format("woff"), url("Roboto-Bold.ttf") format("ttf");
	unicode-range: U+000-5FF; } //Latin glyphs


@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), url("Roboto.woff") format("woff"), url("Roboto.ttf") format("ttf");
	unicode-range: U+000-5FF; } //Latin glyphs

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto-Light"), url("Roboto-Light.woff") format("woff"), url("Roboto-Light.ttf") format("ttf");
	unicode-range: U+000-5FF; } //Latin glyphs
