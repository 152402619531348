.navbar-default {
	background-color: #fff;
	border: none;
	border-radius: 0;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
	font-weight: 700;
	font-size: 14px;
	margin-bottom: 0;
	z-index: 10;
	.navbar-main--mobile {
		background-color: $clr-rojo;
		.navbar-header {
			padding-top: 9px;
			padding-bottom: 9px;
			.navbar-brand {
				float: none;
				padding: 0;
				height: auto;
				display: block;
				img {
					height: 32px;
					width: auto !important; } } }
		.navbar-nav>li>a {
			color: #fff;
			font-weight: 700;
			&:focus, &:hover {
				background-color: darken($clr-rojo, 5%); } }
		.navbar-nav {
			.btn {
				margin-top: 9px;
				margin-bottom: 9px;
				padding: 4px 9px; }
			.btn-default {
				color: $clr-oxford !important;
				&:hover, &:focus {
					background-color: darken(#fff, 7%);
					box-shadow: 0 0 1px rgba(0,0,0, .1) inset;
					color: #000 !important; } } } }
	.btn {
		padding: 6px 10px;
		font-size: 14px;
		margin-top: 0;
		margin-bottom: 0; }
	.navbar-main--dd-notif {
		.dropdown-menu {
			min-width: 300px; }
		a {
			line-height: 20px; } }
	.dropdown {
		a {
			&:focus, &:hover {
				background-color: #fff !important;
				color: $clr-oxford !important; } } }
	.dropdown-menu>li>a {
		&:focus, &:hover {
			color: $clr-oxford;
			text-decoration: none;
			background-color: lighten($clr-oxford, 55%) !important; } }
	.dropdown-menu {
		top: 100%;
		padding: 10px 0;
		margin: 0;
		font-size: 14px;
		background-color: #fff;
		border: none;
		border-radius: 0;
		transition: all .3s ease;
		li>a {
			padding: 15px 20px;
			color: $clr-oxford;
			border-bottom: 1px solid #ececec; }
		li:last-child>a {
			border-bottom: none !important; }
		.item-notificacion {
			border-top: 1px solid $clr-gris-claro;
			min-width: 300px; }
		.fecha-contratacion, .nombre-contratante, .tipo-notificacion {
			display: block; }
		.fecha-contratacion {
			font-size: 12px;
			font-weight: 200; }
		.contratacion {
			background: $clr-rojo;
			display: inline-block;
			padding: .2em .6em .3em;
			font-size: 90%;
			font-weight: 700;
			line-height: 1;
			color: #fff;
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: .25em; }
		.calificacion {
			background: $clr-verde;
			display: inline-block;
			padding: .2em .6em .3em;
			font-size: 90%;
			font-weight: 700;
			line-height: 1;
			color: #fff;
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: .25em; } }
	.navbar-main--top-section {
		background-color: #fff;
		padding-top: 15px;
		padding-bottom: 15px;
		.navbar-nav {
			li>a {
				color: $clr-oxford;
				transition: all .3s ease;
				padding-top: 8px;
				padding-bottom: 8px;
				&:hover, &:focus {
					text-decoration: underline;
					background: transparent; } }
			a.dropdown-toggle {
				&:hover, &:focus {
					color: #333 !important;
					text-decoration: none !important; } } }
		.navbar-brand {
			float: none;
			padding: 0;
			height: auto;
			display: block;
			img {
				height: 38px;
				width: auto !important; } }
		.navbar-form {
			margin-top: 0;
			margin-bottom: 0;
			margin-left: 36px;
			padding-top: 0;
			padding-bottom: 0;
			.input-group {
				.btn-default {
					box-shadow: none;
					border: 1px solid $clr-gris-borde; } } } }
	.navbar-main--bottom-section {
		background-color: $clr-rojo;
		.navbar-nav>li>a {
			color: #fff;
			padding: 15px 20px;
			&:focus, &:hover {
				background-color: darken($clr-rojo, 5%); } }
		.navbar-collapse {
			padding-left: 0 !important;
			padding-right: 0 !important; }
		.navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
			background-color: $clr-amarillo !important;
			color: #000; } }
	//Multilevel DD
	.dropdown-submenu {
		position: relative; }
	.dropdown-submenu > .dropdown-menu {
		top: 0;
		left: 100%;
		margin-top: 0;
		margin-left: -1px;
		-webkit-border-radius: 0 6px 0 6px;
		-moz-border-radius: 0 6px 0 6px;
		border-radius: 0 6px 0 6px; }
	.dropdown-submenu {
		&:hover > .dropdown-menu {
			display: block; } }
	.dropdown-submenu > a:after {
		display: block;
		content: " ";
		float: right;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-width: 4px 0 4px 4px;
		border-left-color: #ccc;
		margin-top: -16px;
		margin-right: 0; }
	.dropdown-submenu:hover > a:after {
		border-left-color: #555; }
	.dropdown-submenu.pull-left {
		float: none; }
	.dropdown-submenu.pull-left > .dropdown-menu {
		left: -100%;
		margin-left: 10px;
		-webkit-border-radius: 6px 0 6px 6px;
		-moz-border-radius: 6px 0 6px 6px;
		border-radius: 6px 0 6px 6px; }
	.navbar-main--dd-productos > a {
		color: #000 !important;
		&:focus, &:hover {
			background-color: darken($clr-amarillo, 3%) !important;
			color: #000 !important; } }
	.navbar-main--dd-productos {
		background-color: $clr-amarillo !important;
		.dropdown-menu {
			min-width: 250px;
			.dropdown-menu--title {
				color: $clr-oxford !important;
				font-weight: 700;
				//padding-left: 20px
				padding-bottom: 5px;
				a {
					font-weight: 700 !important; } } }
		.dropdown-submenu {
			a {
				&:hover, &:focus {
					color: $clr-naranja !important; } }
			.dropdown-menu {
				min-width: 500px;
				min-height: 500px;
				border-radius: 0 2px 0 2px;
				padding-top: 15px;
				padding-bottom: 15px;
				margin-top: -10px;
				li {
					a {
						display: block;
						color: $clr-oxford !important;
						font-weight: 300;
						border-bottom: none;
						padding-top: 2px;
						padding-bottom: 1px;
						&:hover, &:focus {
							background-color: transparent;
							color: $clr-naranja !important; } }
					&:last-child {
						font-size: 12px;
						margin-bottom: 20px; } } } } }
	.navbar-main--dd-txt {
		display: block;
		padding: 3px 20px;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.6;
		color: #777777; }
	.navbar-main--dd-txt-link {
		display: block;
		border: none !important;
		padding: 3px 20px !important;
		font-size: 14px;
		line-height: 1.6;
		color: $clr-rojo !important;
		&:hover, &:focus {
			background-color: transparent !important;
			color: darken($clr-rojo, 8%);
			text-decoration: underline; } }
	.btn-checkout {
		padding: 3px 20px !important; } }
@media (min-width: 768px) {
	.navbar-default {
		.navbar-main--search-mobile, .navbar-main--login-mobile, .navbar-main--profile-mobile, .navbar-main--cart-mobile, .navbar-main--register-mobile {
			display: none; }
		.navbar-main--top-section {
			.navbar-form {
				.input-group {
					.form-control {
						width: 450px; } } } } } }
@media (max-width: 990px) {
	.navbar.navbar-search--mobile {
		min-height: auto;
		margin-bottom: 0;
		border-radius: 0; }
	.navbar-search--mobile {
		background-color: $clr-rojo;
		border-top: 1px solid rgba(0,0,0, .1);
		.navbar-form {
			padding: 0;
			border-top: none;
			border-bottom: none;
			box-shadow: none;
			margin-top: 9px;
			margin-bottom: 9px;
			margin-left: 0;
			margin-right: 0;
			.input-group {
				.form-control {
					height: 32px;
					padding: 5px 10px;
					border: none; }
				.btn {
					padding: 5px 12px; }
				.btn-default {
					background-color: $clr-rojo;
					box-shadow: none;
					border: none;
					color: #fff;
					&:hover, &:focus {
						background-color: darken($clr-rojo, 15%); } } } } } }
@media (max-width: 767px) {
	// .navbar.navbar-search--mobile
	// 	min-height: auto
	// 	margin-bottom: 0
	// .navbar-search--mobile
	// 	background-color: $clr-rojo
	// 	border-top: 1px solid rgba(0,0,0, .1)
	// 	.navbar-form
	// 		padding: 0
	// 		border-top: none
	// 		border-bottom: none
	// 		box-shadow: none
	// 		margin-top: 9px
	// 		margin-bottom: 9px
	// 		margin-left: 0
	// 		margin-right: 0
	// 		.input-group
	// 			.form-control
	// 				height: 32px
	// 				padding: 5px 10px
	// 				border: none
	// 			.btn
	// 				padding: 5px 12px
	// 			.btn-default
	// 				background-color: $clr-rojo
	// 				box-shadow: none
	// 				border: none
	// 				color: #fff
	// 				&:hover, &:focus
	// 					background-color: darken($clr-rojo, 15%)
	.navbar-default {
		.navbar-main--mobile {
			.navbar-brand {
				line-height: 30px; }
			.navbar-nav>li>a {
				padding-top: 5px;
				padding-bottom: 5px;
				.caret {
					float: right;
					margin-top: 9px; } }
			.navbar-nav {
				.navbar-btn {
					margin-left: 15px;
					margin-right: 15px;
					display: block; }
				.open {
					.dropdown-menu {
						padding: 0;
						font-size: 13px;
						background-color: #fff; }
					.dropdown-menu>li>a {
						padding: 3px 15px 3px 25px; } } } }
		.navbar-main--dd-notif.open {
			.dropdown-menu {
				li:last-child {
					padding-bottom: 10px; } } }
		.navbar-main--mobile-list {
			float: right;
			margin: 0;
			li {
				display: inline-block;
				a {
					padding: 5px 9px;
					font-size: 13px;
					font-weight: 300; } } }
		.navbar-main--dd-productos {
			background-color: transparent !important; }
		.navbar-collapse {
			border-color: rgba(0,0,0, .1);
			.navbar-nav {
				margin-top: 0;
				margin-bottom: 0; }
			.navbar-nav>li>a {
				border-bottom: 1px solid rgba(0,0,0, .05);
				&:hover, &:focus {
					background-color: #fff;
					color: $clr-oxford; } } }
		.navbar-header {
			padding-left: 15px;
			padding-right: 15px; }
		.navbar-toggle {
			margin-right: 0;
			margin-top: 0;
			margin-bottom: 0;
			background-color: none;
			border: none;
			&:hover, &:focus {
				background-color: darken($clr-rojo, 5%); }
			.icon-bar {
				background-color: #fff; } }
		.navbar-brand {
			// padding-top: 15px !important
			// padding-bottom: 15px !important
			display: inline-block !important;
			img {
				height: 28px !important;
				display: inline-block !important; }
			// .navbar-toggle
			// 	margin-top: 14px
			// 	margin-bottom: 14px
			// .navbar-default
			// 	text-align: center
			// 	.navbar-nav
			// 		margin: 0 -15px
			// 	.navbar-btn
			// 		margin-top: 15px
			// 		margin-bottom: 15px
			// 		margin-left: 0
			.navbar-nav>li>a {
				padding-top: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid rgba(0,0,0, .15);
				&:hover, &:focus {
					background: rgba(255,255,255, .2); } } } } }
// .navbar-default
// 	.navbar-nav
// 		.open>a
// 			background-color: #fff
// .navbar-default
// 	.navbar-nav>.open>a
// 		&:focus, &:hover
// 			background-color: #fff
.nav {
	.open>a {
		border-color: #ccc;
		&:focus, &:hover {
			border-color: #ccc; } } }
.cart--dd-item-list {
	padding: 3px 20px;
	position: relative;
	margin-left: 0;
	margin-right: 0;
	line-height: normal;
	.cart--dd-item-img {
		padding-left: 0;
		img {
			max-height: 60px; } }
	.cart--dd-item-info {
		padding-right: 0;
		font-size: 12px; }
	.cart--dd-item-name {
		font-weight: 700;
		color: $clr-rojo;
		display: block;
		white-space: normal;
		line-height: normal !important; }
	.cart--dd-item-qty {
		display: block;
		white-space: normal;
		font-weight: 300;
		// .form-control
		// 	height: 29px
		// 	padding: 6px
		// 	font-size: 12px
		// 	margin-top: 5px
		// 	margin-bottom: 5px
		// .input-group-btn
		// 	.btn
		// 		box-shadow: none
		// 		margin-top: 0
		// 		margin-bottom: 0
		// 		font-size: 12px
		// 		padding: 4px 9px
		// 	.btn-default
 }		// 		border: 1px solid $clr-gris-borde
	.cart--dd-item-price {
		display: block;
		font-weight: 700;
		white-space: normal; }
	.cart--dd-item-remove {
		color: lighten($clr-oxford, 25%);
		position: absolute;
		top: -5px;
		right: -5px;
		font-size: 14px; }
	// .dropdown-menu.ddm-fap
	// 	top: 100%
	// 	left: 0
	// 	min-width: 100%
	// 	padding: 10px 0
	// 	margin: 0
	// 	font-size: 14px
	// 	background-color: #fff
	// 	border: none
	// 	border-radius: 0
	// 	transition: all .3s ease
	// .dropdown-menu.ddm-fap>li>a, .dropdown-menu>li>a
	// 	&:focus, &:hover
	// 		color: #fff
	// 		text-decoration: none
 }	// 		background-color: $clr-verde
// @media (max-width: 1200px)
// 	.navbar-default
// 		.dropdown-menu.ddm-fap
// 			font-size: 16px
// 			min-width: 200px
// @media (max-width: 767px)
// 	.navbar-nav .open .dropdown-menu
// 		background-color: #fff
