.notif--icn, .notif--info {
    display: inline-block;
    vertical-align: top; }
.notif--info {
    padding-left: 15px;
    p {
        margin: 0; }
    .notif--contratante {
        color: $clr-verde;
        font-weight: 700;
        font-size: 18px;
        .notif--contratante--mov {
            color: $clr-gris-azul;
            font-weight: 100; } }
    .notif--fecha {
        color: $clr-gris-azul; }
    .estrellas {
        margin-top: 0;
        margin-left: 10px;
        display: inline-block; } }
.notif--tipo--icn-nueva {
    background-color: $clr-rojo;
    color: white;
    display: inline-block;
    padding: 10px 15px;
    font-size: 32px;
    font-weight: 100; }
.notif--tipo--icn-calificada {
    @extend .notif--tipo--icn-nueva;
    background-color: $clr-verde; }
.notif--badge-menu {
    background-color: $clr-rojo;
    color: #fff !important;
    font-size: 9px;
    font-weight: 400;
    padding: 1px 3px 2px 2px;
    border-radius: 100%;
    position: relative;
    top: -13px;
    right: 29px; }
.navbar-default .navbar-nav > .open > a .notif--badge-menu, .navbar-default .navbar-nav > .open > a:hover .notif--badge-menu, .navbar-default .navbar-nav > .open > a:focus .notif--badge-menu {
    color: white; }
@media (max-width: 550px) {
    .notif--icn {
        display: none; }
    .notif--contratante--mov {
        display: block; } }
