// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Brand Styles
@import "btns";
@import "fonts";
@import "footer";
@import "grales";
@import "login";
@import "navbar";
@import "slider";
@import "pages/notificaciones";
@import "animations/fade-in";
@import "pages/social-net";
@import "pages/grales_cuervo";
