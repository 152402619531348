.login--info-right, .login--form-left, .reset-pwd--wrap {
	padding-top: $p-t-lg;
	padding-bottom: $p-b-lg; }
.login--info-right {
	text-align: center;
	img {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $m-b-md; }
	.home--section-title {
		margin-bottom: $m-b-sm; }
	.home--section-subtitle {
		margin-bottom: $m-b-sm; }
	ul, p {
		color: transparentize($clr-oxford, .5);
		font-size: 16px;
		font-weight: 300; }
	li {
		&:last-child {
			margin-bottom: $m-b-md; } } }
.login--form-left {
	.home--section-title {
		text-align: center;
		margin-bottom: $m-b-sm; }
	.home--section-subtitle {
		text-align: center;
		margin-bottom: $m-b-md; }
	.btn-default {
		border: 1px solid $clr-gris-borde; } }
.reset-pwd--wrap {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	.home--section-title {
		margin-bottom: $m-b-sm; }
	.home--section-subtitle {
		margin-bottom: $m-b-md; } }
.reset-change-password {
	 margin-top: 25px; }
@media (min-width: 768px) {
	.reset-pwd--wrap {
		max-width: 400px; } }
