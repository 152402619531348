.product-category {
    > li {
        color: #000;
        opacity: 0.95;
        vertical-align: middle;
        width: 250px;
        height: 50px;
        font-size: 15px;
        display: inline-block;
        > a {
            &:hover {
                opacity: 0.95;
                text-align: center;
                vertical-align: middle; } } } }
.navbar-default .dropdown-submenu > a:after {
    margin-top: 8px; }
.dropdown-menu--title li a {
    color: $clr-rojo !important; }
.titulo-menu {
  color: $clr-rojo; }
.navbar-default .navbar-main--dd-productos .dropdown-submenu .dropdown-menu li a {
  &:hover, &:focus {
    background-color: transparent;
    color: $clr-rojo !important; } }
.navbar-default .navbar-main--dd-productos .dropdown-submenu a {
  &:hover, &:focus {
    background-color: transparent;
    color: $clr-rojo !important; } }
.img-cuervito {
    >img {
        /*opacity: 0.2*/
        position: relative; } }
.descripcion_beneficio {
    text-align: justify;
    margin-left: 30px;
    line-height: 1.5; }
.nosotros--hero-main {
    position: relative;
    text-align: center; }
.imageSection {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.2; }
.container {
  text-align: left; }
.descripcion_beneficio {
    text-align: justify;
    margin-left: 30px;
    line-height: 1.5; }
.quien_es_cuervo {
    font-size: 30px;
    position: absolute;
    top: 18%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    opacity: 1.0;
    > b {
        color: $clr-rojo; } }
.all--categories {
    margin-bottom: 50px; }
.all--categorie {
    margin-bottom: 50px; }
.post-content {
    color: #fff;
    margin: -50px 20px 12px;
    position: relative; }
.post-content h3 {
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    color: #000;
    font-family: inherit; }
@media (max-width: 767px) {
    .all--categorie {
        margin-bottom: 46px; }
    .all--categories {
        margin-bottom: 12px; }
    .all--categories img.img-responsive {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important; }
    .all--categorie img.img-responsive {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important; }
    .padding-bottom {
        padding-bottom: 20px; }
    .post-content {
        padding-left: 100px; } }
@media (min-width: 768px) {
    .padding-bottom {
        padding-bottom: 0px; }
    .post-content {
        padding-left: 0px; } }
.breadcrumb a {
    color: $clr-rojo;
    font-weight: bold;
    font-size: 18px; }
.breadcrumb {
    font-size: 18px !important;
    padding: 0;
    @include m-b-md;
    background-color: transparent;
    border-radius: 0; }
.padding-bottom {
     padding-bottom: 40px; }
.border-radius {
    border-radius: 30px; }
.img-alt {
    height: 270px; }
.productt h3 {
    font-size: 18px !important; }
.dropdown-menu--title {
    clear: both; }
.enfatizarTitulo {
    font-weight: bold; }
@media (max-width: 527px) {
    .post-content {
        padding-left: 0px; } }
.addressesTitle {
    font-weight: bold;
    color: $clr-rojo !important; }
.btnDomicilios {
  &:focus {
    color: $clr-gris;
    background-color: $clr-amarillo;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 4px;
    user-select: none;
    box-shadow: 0 2px 0.25px rgba(0, 0, 0, 0.15);
    text-decoration: none; }
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: $clr-gris-claro;
  text-decoration: none;
  &:hover {
    color: $clr-gris;
    text-decoration: none; } }
.tabbable-panel {
  border: 1px solid #eee;
  padding: 10px; }

/* Default mode */

.tabbable-line > {
  .nav-tabs {
    border: none;
    margin: 0px;
    > li {
      margin-right: 2px;
      > a {
        border: 0;
        margin-right: 0;
        color: #737373;
        > i {
          color: #a6a6a6; } }
      &.open, &:hover {
        border-bottom: 4px solid gainsboro; }
      &.open > a, &:hover > a {
        border: 0;
        background: none !important;
        color: #333333; }
      &.open > a > i, &:hover > a > i {
        color: #a6a6a6; }
      &.open .dropdown-menu, &:hover .dropdown-menu {
        margin-top: 0px; }
      &.active {
        border-bottom: 4px solid #636b6f;
        position: relative;
        > a {
          background-color: #fff;
          border: 0;
          color: #333333;
          > i {
            color: #404040; } } } } }
  .tab-content {
    margin-top: -3px;
    background-color: #fff;
    border: 0;
    border-top: 1px solid #eee;
    padding: 15px 0; } }

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0; }

/* Below tabs mode */

.tabbable-line.tabs-below > {
  .nav-tabs > li {
    border-top: 4px solid transparent;
    > a {
      margin-top: 0; }
    &:hover {
      border-bottom: 0;
      border-top: 4px solid #fbcdcf; }
    &.active {
      margin-bottom: -2px;
      border-bottom: 0;
      border-top: 4px solid #f3565d; } }
  .tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px; } }

.navDashboard {
  margin-bottom: 30px;
  border-bottom: 1px solid gainsboro; }

.addressesTitle {
  margin-bottom: 25px; }
.addressEmphatic {
    color: $clr-rojo;
    text-decoration: underline; }
.linkNew_address {
    > b {
        color: $clr-gris; } }
.product-category--img {
    img {
        max-width: 320px; } }
.home--section-title-dashboard {
  color: $clr-rojo;
  font-weight: bold; }

.balanceBox {
  margin: 20px 0px;
  padding: 15px;
  -webkit-box-shadow: -1px 0px 8px -2px rgba(99, 99, 99, 0.78);
  -moz-box-shadow: -1px 0px 8px -2px rgba(99, 99, 99, 0.78);
  box-shadow: -1px 0px 8px -2px rgba(99, 99, 99, 0.78);
  border-radius: 5px;
  border: 1px solid gainsboro; }

.checkBalance_text {
  vertical-align: middle;
  font-weight: bold;
  color: $clr-rojo;
  font-size: 14px; }

.dashboardTitle {
  margin: 20px 0px; }

.actualBalance_title, .expiredBalance_title {
  width: 300px;
  text-align: left;
  margin-right: 15px;
  font-size: 13px; }

.linkDashboard {
  width: 300px;
  text-decoration: underline;
  font-size: 12px; }

.amount_Dashboard {
  color: $clr-gris;
  font-weight: bolder;
  vertical-align: middle; }

.seccionDashboard {
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px; }

.graphicContainer {
  padding: 0px;
  overflow: hidden; }

.extended {
  line-height: 1;
  padding: 0px;
  margin: 15px 0px; }

.graficPercent {
  height: 50px;
  width: 120px;
  background-color: green;
  border-radius: 8px;
  display: inline-block; }

.porcentajeDashboard {
  font-size: 28px;
  margin-left: 20px;
  opacity: 0.5;
  display: inline-block; }
.percentBox {
  margin-top: 8px;
  width: 500px;
  height: 50px;
  vertical-align: middle; }
.pagination {
    margin: 0px; }
.cart--promos--slider > li {
    list-style: none; }
.link-branch {
  color: #d9d9d9 !important;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 2px solid #d9d9d9;
  font-size: 18px;
  letter-spacing: 4.6px !important;
  &:last-child {
    border-right: none; } }
.footer-branches {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center; }
.homeSubtitle {
  font-size: 32px;
  color: $clr-gris; }
.homeTitle {
  font-size: 32px;
  color: $clr-gris;
  font-weight: bold; }
.homeCategory {
  border-radius: 20px;
  img {
    max-width: 360px; } }
.confirmationSubtitle {
  color: $clr-gris !important; }
.table {
  font-size: 17px;
  .tbl-value {
    color: $clr-gris !important; } }
.homeCategories {
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: $clr-gris;
    &:hover {
      color: $clr-rojo; } } }
.mainTitleFaqs {
  color: $clr-rojo; }
.subtitulo-verde {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #3c3c3b; }

.temaFaqs a {
  &:active {
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
    color: $clr-rojo; }
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: $clr-rojo; }

.preguntaFaqs a {
  font-size: 14px;
  padding: 20px 0px;
  margin: 20px 0px;
  color: #FFF;
  font-weight: bold;
  opacity: 0.95;
  &:hover {
    text-decoration: none; } }

.paginatorFaqs {
  border-top: 1px solid gainsboro; }
.containerTable {
  padding-top: 40px;
  padding-right: 40px;
  img {
    width: 10px;
    height: 10px; }
  td {
    vertical-align: top;
    text-align: left;
    font-size: 14px; } }

.textColumn {
  margin-left: 5px; }

.btnRegistro {
  text-decoration: underline;
  font-weight: bold; }
.listaSubcats {
  div {
    text-align: left;
    height: 30px;
    li a {
      font-size: 15px; } }
  li:hover {
    background-color: #FFF; } }
.cont_cats {
  max-height: 300px; }
.firstLineMenu {
  height: 60px; }
.footer-superior {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255,255,255, .1);
  color: transparentize(#fff, .2);
  font-weight: 300;
  ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  li {
    line-height: 1;
    font-size: 17px;
    margin-bottom: 10px;
    i {
      color: $clr-amarillo;
      margin-right: 5px; } }
  li>a {
    font-size: 17px;
    color: transparentize(#fff, .1); }
  .footer--social-item {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      img {
        height: 32px;
        margin-right: 5px; } } } }
footer {
  padding-top: 30px;
  padding-bottom: 10px; }
