body {
	font-family: "Roboto";
	background-color: #fff;
	color: $clr-oxford; }
header {
	background: $clr-azul; }
a {
	color: $clr-rojo;
	&:hover, &:focus {
		color: darken($clr-rojo, 15%); } }

/////////Heros
.hero--wrap-body {
	@include m-t-lg; }
.hero--wrap-body-sm {
	@include m-t-md; }
////// Clase del Base
.home-hero--wrap {
	margin-top: $m-t-md;
	.list-group {
		margin-bottom: 0;
		.home-hero--title {
			border-top-left-radius: 4px;
			background-color: #fff;
			border: 1px solid $clr-gris-borde;
			display: block;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.6;
			margin-top: 0;
			margin-bottom: -1px;
			position: relative;
			padding: 15px; }
		.list-group-item {
			border: 1px solid $clr-gris-borde;
			padding: 15px; }
		.list-group-item {
			&:last-child {
				border-bottom-right-radius: 0; } } } }
.home-hero--featured-categories--wrap {
	padding-right: 0; }
.home--featured-products--wrap {
	padding-top: $p-t-md;
	padding-bottom: $p-b-md;
	text-align: center; }
.hero-generic--wrap-default {
	background-color: #fff;
	padding-top: $p-t-lg;
	padding-bottom: $p-b-lg;
	p, ul>li {
		color: transparentize($clr-oxford, .25); }
	.img-responsive {
		display: inline; } }
.hero-generic--wrap-default-sm {
	padding-top: $p-t-md;
	padding-bottom: 0;
	h4 {
		color: transparentize($clr-rojo, .5);
		margin-top: 0;
		margin-bottom: 5px; }
	h3 {
		margin-top: 0; }
	h2 {
		color: $clr-rojo;
		font-size: 36px;
		margin-top: 0;
		margin-bottom: 0; } }
.hero-generic--wrap-primary {
	background-color: $clr-rojo;
	color: #fff;
	padding-top: $p-t-lg;
	padding-bottom: $p-b-lg;
	text-align: center; }
.hero-generic--wrap-gray {
	background-color: #efefef;
	color: $clr-oxford;
	padding-top: $p-t-lg;
	padding-bottom: $p-b-lg; }
.hero-generic--wrap-primary {
	.row {
		[class|=col] {
			padding-left: 30px;
			padding-right: 30px; } }
	img {
		height: 96px; }
	.home--section-title {
		color: #fff !important; }
	.home--section-subtitle {
		color: transparentize(#fff, .25) !important; } }
.generic--spec-title {
	font-size: 18px;
	font-weight: 400; }
.generic--spec-desc {
	font-size: 16px;
	font-weight: 300; }
.generic--spec-title--primary {
	color: $clr-rojo;
	font-size: 18px;
	font-weight: 400; }
@media (min-width: 769px) {
	.home-hero--slider--wrap {
		padding-left: 0; } }
@media (max-width: 767px) {
	.hero-generic--wrap-default, .hero-generic--wrap-default-sm {
		text-align: center !important;
		.img-responsive {
			display: block;
			margin-bottom: $m-t-lg;
			margin-left: auto;
			margin-right: auto; } }
	.hero-generic--wrap-primary {
		[class|=col] {
			margin-bottom: $m-b-md; } } }


///////////Home title
.home--section-title.homeTitle {
	font-size: 35px;
	text-align: left; }
.home--section-subtitle.homeSubtitle {
	font-size: 35px;
	text-align: left;
	margin-bottom: 10px; }
h4.home--section-subtitle {
	font-size: 18px; }
.home--section-title {
	font-size: 35px;
	font-weight: bold !important;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 10px; }
.list-unstyled {
	h3.home--section-subtitle {
		font-size: 18px; } }
.home--section-subtitle {
	//color: transparentize($clr-oxford, .5)
	font-size: 35px;
	margin-top: 0;
	margin-bottom: 0; }
.home--section-txt-featured {
	color: $clr-oxford;
	font-size: 16px;
	font-weight: 300;
	margin-top: 0;
	margin-bottom: 0; }
.hero-generic--wrap-default {
	li {
		.home--section-subtitle {
			@include m-b-sm; }
		&:last-child {
			@include m-b-md; } } }
@media (max-width: 768px) {
	.home--section-title {
		font-size: 18px;
		margin-bottom: $m-b-sm; }
	.home--section-subtitle {
		font-size: 14px; } }

///Pages
.promotions--container-md {
	position: relative;
	width: 100%;
	height: 280px;
	overflow: hidden;
	margin-top: $m-t-md;
	margin-bottom: $m-b-md;
	border-radius: 20px;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%; }
	.promotions--title {
		position: absolute;
		top: 40px;
		left: 30px;
		width: 80%;
		font-size: 64px;
		font-weight: 700;
		color: #fff;
		text-shadow: 2px 2px 3px rgba(0,0,0, .4); }
	.promotions--subtitle {
		position: absolute;
		top: 124px;
		left: 30px;
		width: 80%;
		line-height: 1.2;
		font-size: 32px;
		font-weight: 700;
		color: #fff;
		text-shadow: 2px 2px 2px rgba(0,0,0, .2); }
	.promotions--resctrictions {
		position: absolute;
		bottom: 30px;
		left: 30px;
		width: 90%;
		font-size: 11px;
		font-weight: 700;
		color: #fff;
		text-shadow: 1px 1px 1px rgba(0,0,0, .5); } }
@media (max-width: 1200px) {
	.promotions--container-md {
		height: auto;
		max-height: 360px;
		img {
			width: auto; } } }
.downloadables--wrap {
	padding-top: $p-t-md;
	padding-bottom: $p-b-md;
	.table {
		img {
			display: inline-block;
			margin-right: 10px; } } }
.profile--wrap {
	padding-top: $p-t-md;
	padding-bottom: $p-b-md;
	word-break: break-all;
	hr {
		border-top: 1px solid $clr-gris-borde; }
	.profile--btn-upgrade {
		margin-right: $m-r-sm; } }
.account--wrap {
	padding-top: $p-t-md;
	padding-bottom: $p-b-md;
	.well {
		padding: 30px 0;
		margin-bottom: $m-b-md;
		border: none;
		border-radius: 0;
		box-shadow: none; }
	.form-group {
		margin-bottom: $m-b-md;
		label {
			margin-top: 4px;
			padding: .3em .6em .2em; } }
	h3 {
		margin-top: 5px; }

	fieldset {
		display: block;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-block-start: 0;
		padding-block-end: 10px;
		padding-inline-start: 15px;
		padding-inline-end: 0;
		border: 1px groove $clr-gris-borde;
		min-width: -moz-min-content;
		margin-bottom: $m-b-md; }
	fieldset > legend {
		padding-inline-start: 2px;
		padding-inline-end: 2px;
		inline-size: -moz-fit-content;
		display: inline;
		width: auto;
		margin-bottom: -6px;
		font-size: 14px;
		border-bottom: 0; } }
.label-warning {
	color: $clr-gris; }
.account-overview--title {
	text-transform: uppercase;
	font-weight: 700; }
.account-overview--subtitle {
	display: block;
	text-transform: uppercase;
	font-weight: 300;
	font-size: 14px; }
.product-categories--wrap {
	padding-top: $p-t-lg;
	padding-bottom: 0; }
.orders--wrap, .wishlist--wrap, .cart--wrap, .product-categories--wrap-xs, .products--wrap, .faqs--wrap, .generic--wrap {
	padding-top: $p-t-md;
	padding-bottom: $p-b-lg;
	.panel {
		margin-bottom: 0; } }
.wishlist--wrap, .cart--wrap {
	.panel {
		border: none;
		box-shadow: none; }
	.table-hover {
		tbody>tr.wishlist--tbl-divider {
			&:hover {
				background-color: #f1f1f1; } } }
	.wishlist--tbl {
		margin-bottom: 0;
		tr > td {
			padding: 4px; }
		td, th {
			font-size: 13px;
			vertical-align: middle;
			line-height: 1.6; }
		.wishlist--sku {
			display: block;
			font-size: 11px;
			font-weight: 300; }
		.wishlist--article-name {
			display: block;
			font-size: 18px;
			font-weight: 300; }
		.wishlist--qty-requested {
			font-weight: 300; }
		.wishlist--qty-delivered {
			font-weight: 700; }
		.wishlist--unit-price {
			font-weight: 300; }
		.wishlist--total {
			font-weight: 700; }
		.wishlist--link {
			color: $clr-oxford; }
		.btn-primary {
			margin-bottom: 10px; } } }
.list--link-add-cart {
	color: $clr-oxford;
	&:hover, &:focus {
		color: darken($clr-oxford, 10%); } }
.wishlist--link-remove {
	color: lighten($clr-oxford, 25%); }
.cart--wrap {
	.wishlist--article-name {
		font-size: 15px !important;
		font-weight: 400; }
	.cart--bill-wrap {
		background-color: #f5f5f5;
		border-radius: 10px; }
	.cart--bill-wrap.success {
		background-color: transparent;
		border-radius: 0;
		.panel-body {
			padding: 30px; }
		.home--section-subtitle {
			font-weight: 400;
			color: $clr-oxford;
			margin-bottom: $m-b-sm; }
		.table {
			font-size: 13px;
			font-weight: 300;
			.tbl-value {
				font-weight: 400; }
			.tbl-lbl-featured {
				font-size: 15px;
				font-weight: 400; }
			.tbl-value-featured {
				font-size: 15px;
				font-weight: 700; }
			tbody>tr {
				&:first-child {
					td {
						border-top: none;
						.img-responsive {
							max-height: 64px; } } } } } }
	.cart-wizard {
		margin-bottom: $m-b-md; }
	.cart--wizard-item {
		display: block;
		border-bottom: 5px solid $clr-gris-borde;
		color: $clr-gris-borde;
		padding-bottom: $p-b-sm; }
	.cart--wizard-item.past {
		border-bottom-color: transparentize($clr-rojo, .5);
		color: transparentize($clr-rojo, .5); }
	.cart--wizard-item.current {
		border-bottom-color: $clr-rojo;
		color: $clr-oxford; } }
.cart--bill-wrap {
	.wishlist--article-name {
		font-size: 13px !important; }
	.wishlist--unit-price, .wishlist--link-remove {
		font-size: 11px !important; } }
.cart--bill-help {
	padding: 0;
	margin: 0;
	list-style-type: none;
	font-weight: 300;
	.cart--bill-help--title {
		font-weight: 400;
		font-size: 16px;
		margin-bottom: $m-b-sm; }
	.cart--bill-help--subtitle {
		font-weight: 700; } }
.product-categories--wrap-xs {
	.panel-group {
		.panel-title {
			a {
				display: block;
				&:after {
					display: block;
					content: " ";
					float: right;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 4px solid #ccc;
					margin-top: 8px;
					margin-right: 0; } } } } }
.modal-body {
	.products--wrap {
		background-color: #fff;
		padding-top: 0;
		padding-bottom: 0; } }
.products--wrap {
	background-color: #ffffff;
	/*background-color: #f5f5f5*/
	.form-control-static {
		font-size: 14px;
		color: $clr-oxford;
		min-height: auto; }
	hr {
		border-color: $clr-gris-borde; }
	.product-detail--logo-trust {
		height: 68px; } }
.products--filters-wrap {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 1px 1px 1px rgba(0,0,0, .1);
	border-top: 4px solid $clr-rojo;
	padding: 15px;
	.products--filters-title {
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 0; }
	ul {
		list-style-type: none;
		padding: 0; }
	.products--filters-section {
		font-weight: 700;
		margin-bottom: 7px; }
	.products--filters-category {
		color: $clr-oxford; }
	.products--filters-category.selected, .products--filters-child.selected {
		color: $clr-gris-borde !important; }
	.products--filters-child.selected {
		@include m-b-xs; }
	.products--filters-child {
		padding-left: 15px; }
	hr {
		@include m-t-sm;
		@include m-b-sm; }
	.ipt-price--filter {
		display: inline-block;
		width: 38%; } }
.products--pagination-wrap {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 1px 1px 1px rgba(0,0,0, .1);
	padding: 15px;
	.btn-group {
		vertical-align: unset; }
	.pagination {
		margin: 0 0 0 0px; }
	p {
		margin: 7px 0 0 0; }
	@include m-b-md; }
.product-detail--info-wrap {
	.product-detail--info-sku {
		display: block;
		color: darken($clr-gris-borde, 20%);
		font-size: 12px;
		font-weight: 300;
		@include m-b-xs; }
	.label-danger {
		display: inline-block;
		font-size: 14px;
		@include m-b-xs; }
	.product-detail--info-title {
		font-size: 21px;
		margin-top: 0;
		@include m-b-xs; }
	.product-detail--info-desc {
		color: $clr-oxford;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.6;
		margin-top: 0;
		@include m-b-sm; }
	.product-detail--info-price {
		color: $clr-rojo;
		display: block;
		font-size: 21px;
		font-weight: 700;
		margin-top: 0;
		@include m-b-sm; }
	.product-detail--info-price-old {
		color: $clr-oxford;
		display: block;
		font-size: 21px;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 0;
		text-decoration: line-through; }
	.btn-primary {
		display: inline-block;
		@include m-b-sm; }
	.form-group {
		@include m-b-xs; }
	hr {
		border-color: $clr-gris-borde; } }
.product-detail--info-logo {
	display: block;
	height: 24px; }
.product-detail--specs-title {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: $m-b-sm; }
.product-detail--specs-tbl {
	.table {
		font-size: 13px;
		font-weight: 300;
		.tbl-value {
			font-weight: 700; }
		tbody>tr {
			&:first-child {
				td {
					border-top: none; } } } } }
.nosotros--hero-main {
	@include p-t-lg;
	@include p-b-lg;
	background-image: url('/public/assets/images/slider-home/slider-home-01.jpg');
	background-size: cover;
	background-color: darken($clr-rojo, 5%);
	color: #fff;
	h4 {
		font-size: 18px;
		font-weight: 400;
		margin-top: 0;
		@include m-b-sm; }
	h2 {
		font-size: 36px;
		font-weight: 700;
		margin-top: 0;
		@include m-b-sm; }
	h3 {
		font-size: 21px;
		font-weight: 300;
		max-width: 500px;
		margin-top: 0;
		margin-bottom: 0; } }
.nosotros--hero-introducction, .nosotros--hero-values {
	background-color: #fff;
	@include p-t-lg;
	@include p-b-lg;
	ul {
		padding-left: 0;
		list-style-type: none; }
	li, td {
		font-size: 18px;
		font-weight: 300;
		vertical-align: middle; }
	tr {
		height: 48px;
		vertical-align: top; }
	.glyphicon {
		font-size: 28px;
		color: $clr-rojo;
		margin-right: 7px; } }
.nosotros--hero-introducction {
	@include p-t-xlg;
	@include p-b-xlg; }
.nosotros--hero-title--light {
	font-size: 28px;
	font-weight: 300;
	margin-top: 0;
	margin-bottom: $m-b-xs; }
.nosotros--hero-title {
	font-size: 28px;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: $m-b-xs; }
.nosotros--hero-objectives {
	background-color: lighten($clr-rojo, 40%);
	@include p-t-lg;
	@include p-b-lg;
	p {
		font-size: 18px;
		font-weight: 300; } }
@media (min-width: 768px) {
	.wishlist--wrap {
		.table-responsive {
			overflow-x: visible; } }
	.cart--wrap {
		.direcciones--link {
			text-align: right; } } }
@media (max-width: 768px) {
	.profile--wrap, .orders--wrap, .wishlist--wrap, .product-categories--wrap, .product-categories--wrap-xs, .products--wrap, .downloadables--wrap {
		padding-top: $p-t-sm;
		padding-bottom: $p-b-sm;
		.home--section-title {
			margin-bottom: $m-b-sm; } }
	.cart--wrap {
		margin-bottom: $m-b-sm;
		.cart--wizard-item {
			border-bottom-width: 4px;
			font-size: 12px;
			padding-bottom: $p-b-xs; } }
	.cart--cards-list {
		li {
			padding: 5px; } }
	.products--pagination-wrap {
		@include m-b-sm;
		.text-right {
			text-align: left !important; }
		.pagination {
			margin-left: 0; }
		p {
			margin-top: 0; } } }
.cart--cards-list {
	padding: 0;
	li {
		border: 1px solid $clr-gris-borde;
		padding: 10px;
		display: inline-block;
		height: 48px;
		img {
			height: 100%; } } }

//Pre-register
.pre-registro--img {
	margin-bottom: 15px; }

//Contact
.map-wrap {
	ng-map {
		width: 100%;
		height: 950px; } }
.contact-list {
	padding-left: 0;
	line-height: 2.5;
	list-style-type: none;
	font-size: 16px;
	li {
		.glyphicon {
			color: $clr-amarillo;
			font-size: 18px;
			margin-right: 5px; }
		img {
			height: 24px;
			margin-right: 5px; }
		a {
			color: $clr-oxford; } } }


.generic--wrap {
	hr {
		border-top-color: #ddd; } }
//Blog
.blog--category-list {
	padding-left: 0;
	line-height: 2.5;
	list-style-type: none;
	font-size: 16px;
	font-weight: 300;
	li {
		border-bottom: 1px solid $clr-gris-borde;
		.glyphicon {
			color: $clr-rojo;
			font-size: 18px;
			margin-right: 5px; }
		a {
			color: $clr-oxford;
			&:hover, &:focus {
				color: $clr-rojo; } } } }
.blog--detail--title {
	margin-top: 0;
	margin-bottom: 30px; }
.blog--detail--text {
	font-size: 16px; }
.blog--detail--img {
	display: block;
	max-width: 100%;
	height: auto;
	@include m-t-md;
	@include m-b-md; }

///Modals
.modal {
	.modal-subtitle {
		margin-top: 0;
		margin-bottom: $m-b-sm;
		font-size: 18px;
		font-weight: 300; }
	.form-group-small {
		label {
			font-size: 13px;
			font-weight: 300; } } }
.modal-condensed {
	.modal-header, .modal-footer {
		padding: 7px 15px;
		.modal-title {
			font-size: 16px; } }
	.table {
		margin-bottom: 0;
		font-size: 12px;
		td, th {
			vertical-align: middle; }
		.checkbox {
			margin-top: 0;
			margin-bottom: 0; }
		thead > tr > th, thead > tr > td, tbody > tr > th, tbody > tr > td, tfoot > tr > th, tfoot > tr > td {
			padding: 2px; } } }
//pagination
.pagination {
	li {
		a, span {
			color: $clr-rojo;
			box-shadow: 0 2px .25px rgba(0,0,0, .05); } }
	.active {
		a {
			background-color: $clr-rojo;
			border-color: $clr-rojo; } } }

////Datatables
.table-hover {
	tbody>tr {
		&:hover {
			background-color: lighten($clr-rojo, 55%); } } }
.dataTables_wrapper, .table {
	.dataTables_info {
		display: inline-block !important; }
	div.dataTables_info {
		padding-top: 0px !important; }
	.dataTables_paginate {
		.paginate_button {
			padding: 6px 8px !important {
		    color: $clr-oxford !important; }
			border: 1px solid $clr-gris-borde !important;
			border-radius: 3px !important;
			background: #fff !important;
			&:hover {
				background: transparent !important;
				border: none !important; } }
		.paginate_button.previous, .paginate_button.next {
			border: none !important; }
		.current {
			color: $clr-oxford !important;
			border: 1px solid darken($clr-gris-borde, 10%) !important;
			border-radius: 3px !important;
			background: darken(#fff, 10%) !important;
			&:hover {
				color: $clr-oxford !important;
				border: 1px solid $clr-gris-borde !important;
				border-radius: 3px !important;
				background: #fff !important; } } } }

.dataTables_filter {
	margin-top: -5px !important;
	label {
		margin-bottom: 12px !important; }
	input[type="search"] {
		border: 1px solid $clr-gris-borde !important;
		margin-left: 8px; } }
.panel {
	.dataTables_wrapper {
		.dataTables_length {
			select {
				border: 1px solid $clr-gris-borde !important;
				margin-left: 8px;
				margin-right: 8px; } } } }
//images
.img-viñeta-32 {
	height: 32px;
	margin-right: 5px; }
.img-viñeta-24 {
	height: 24px;
	margin-right: 5px; }
.img-cuervito {
	text-align: center; }

//Breadcrumb
.breadcrumb {
	padding: 0;
	@include m-b-md;
	background-color: transparent;
	border-radius: 0; }

//Productos
.btn-show--filters {
	display: none; }
.overlay {
	height: 100%;
	width: 0;
	opacity: 0;
	position: fixed;
	z-index: 20;
	left: 0;
	top: 0;
	background-color: #fff;
	overflow-x: hidden;
	transition: all 0.3s ease-in-out;
	a.closebtn {
		color: $clr-oxford;
		transition: all 0.2s ease;
		&:hover, &:focus {
			text-decoration: none;
			color: darken($clr-oxford, 25%); } } }
.titulo-overlay {
	font-size: 14px;
	color: $clr-rojo;
	position: relative;
	left: 15px;
	top: 15px; }
.overlay-content {
	position: relative;
	width: 100%;
	top: 32px;
	padding-left: 15px;
	padding-right: 15px; }
.overlay .closebtn {
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 21px; }
.product-categories--wrap-xs {
	.panel-group {
		margin-bottom: 0;
		.list-group {
			margin: -15px; }
		.list-group-item {
			border-left: 0;
			border-right: 0;
			padding-left: 30px;
			&:first-child {
				border-top: 0; }
			&:last-child {
				border-bottom: 0; } } }
	.panel-group .panel+.panel {
		margin-top: -1px; }
	.panel-title {
		font-size: 14px;
		font-weight: bold;
		line-height: 1.6; } }

.product-category--item {
	border-radius: 20px;
	display: block;
	margin-bottom: $m-b-lg;
	transition: all .2s ease-in-out;
	h2 {
		color: $clr-oxford;
		font-size: 18px;
		font-weight: 300;
		@include m-t-sm;
		margin-bottom: 0; }
	&:hover, &:focus {
		text-decoration: none;
		img {
			transform: scale(1.1);
			filter: brightness(110%); } } }
.product-category--img {
	height: 200px;
	overflow: hidden;
	display: flex;
	img {
		object-fit: cover;
		transition: all .2s ease-in-out; } }
.product-category--item.featured {
	position: relative;
	.product-category--img {
		height: 300px;
		img {
			width: unset !important;
			height: unset !important;
			object-fit: contain !important; } }
	h2 {
		color: #fff;
		font-size: 24px;
		margin-top: 0;
		position: absolute;
		left: 30px;
		bottom: 30px;
		text-shadow: 0 0 2px rgba(0,0,0, .5); } }
.img-mascara {
	background-image: linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, 0.6));
	width: 100%;
	height: 100%; }
@media (max-width: 992px) {
	.btn-show--filters {
		display: block; } }
@media (max-width: 768px) {
	.product-category--item {
		margin-bottom: $m-b-sm; }
	.breadcrumb {
		@include m-b-sm; } }

//list-group
.list-group-item {
	&:hover, &:focus {
		background-color: lighten($clr-rojo, 42%) !important;
		text-decoration: underline !important;
		color: $clr-rojo !important; } }
.hero-nosotros {
	min-height: 400px;
	//background: url("../../imgs/profesionistas-equipo-min.jpg") no-repeat 50% 25%
	background-size: cover; }
.nosotros-img {
	min-height: 400px;
	//background: url("../../imgs/profesionista.jpg") no-repeat 50% 0
	background-size: cover; }
.flex-container {
	display: flex;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	justify-content: top; }

@media (max-width: 768px) {
	.contacto {
		[class|=col] {
			text-align: center; } } }

.mis-pagos {
	.label.label-fap {
		font-size: 75%;
		padding: .2em .6em .3em;
		text-transform: none; } }



///Panels
.panel-body-md {
	padding: 30px; }
@media (max-width: 768px) {
 .panel-body-md {
 	padding: 15px; } }
.panel-content-title {
	background: #F7971B;
	color: white;
	font-weight: bold;
	font-size: 21px;
	padding: 15px; }
.panel-content-body {
	padding: 15px;
	border-color: #d3e0e9;
	background-color: #fff; }
.panel-title {
	font-weight: bold; }


.label-danger {
	background-color: $clr-rojo; }
.label-warning {
	background-color: $clr-amarillo; }
.label-rojo {
	background-color: $clr-rojo; }
.n-m-b {
	margin-bottom: 0 !important; }
.n-m-l {
	margin-left: 0 !important; }
.n-m-r {
	margin-right: 0 !important; }
.n-p-l {
	padding-left: 0 !important; }
.n-p-r {
	padding-right: 0 !important; }
.margin-auto {
	margin-left: auto;
	margin-right: auto; }
.padtop60 {
	padding-top: 60px; }
.m-b-15 {
	margin-bottom: 15px !important; }
.m-b-30 {
	margin-bottom: 30px !important; }
.marginbot60 {
	margin-bottom: 60px !important; }
.margintop30 {
	margin-top: 30px; }
.required-field-label {
	color: #ff0000;
	margin-left: 5px; }
.display-flex {
	display: flex;
	align-items: center;
	justify-content: center; }
@media (max-width: 768px) {
	.display-flex {
		display: block; } }
//Forms
legend {
	margin-bottom: $m-b-sm;
	padding-bottom: $p-b-xs;
	font-size: 18px;
	color: $clr-oxford; }
label {
	margin-bottom: 3px;
	font-weight: 400; }
hr {
	margin-top: $m-t-md;
	margin-bottom: $m-b-md; }
.form-control-static {
	color: $clr-gris-azul;
	font-size: 21px;
	font-weight: 200;
	margin: 0;
	padding: 0; }
.form-control {
	&:focus {
		border-color: $clr-rojo; } }
.input-xs {
	height: 24px;
	padding: 3px 4px;
	font-size: 13px;
 }	//line-height: 1.6
@media (max-width: 768px) {
	form {
		.row {
			div.col-xs-12 {
				&:nth-child(2) {
					margin-top: $m-b-sm; } } } }
	hr {
		@include m-t-sm;
		margin-bottom: $m-b-sm; }
	.form-control {
		height: 28px;
		padding: 4px 8px; }
	label {
		margin-bottom: 0; } }

// Styles on app.sass
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important; }
.dt-qtip-error .qtip-tip, .dt-qtip-error {
	background-color: #ebe2e2 !important;
	color: #7c0b0b !important; }
.dt-qtip-error.qtip-default {
	border: 1px solid #b00b0b !important; }
.icon-flipped {
	transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1); }
.no-padding {
	padding: 0!important; }
.fadeshow {
	transition: all linear 0.5s; }
.fadeshow.ng-hide {
	opacity: 0; }
.animate-enter {
	animation: enter 725ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
	display: block;
	position: relative; }
.animate-enter.ng-leave {
	animation: 0.5s leave-animation;
	display: inline-block;
	overflow: hidden; }
.languages-form.ng-leave,
.languages-form.ng-leave.ng-leave-active,
.languages-form.ng-hide-add {
	animation: 0.5s leave-form-languages; }
.languages-form.ng-enter,
.languages-form.ng-hide-remove {
	animation: 0.5s enter-form-languages; }
.languages-form {
	height: auto;
	overflow: hidden;
	margin-bottom: 10px; }
.language {
	min-width: 12em !important; }
@keyframes leave-form-languages {
	from {
		opacity: 1;
		height: 160px;
		right: 0px; }
	to {
		opacity: 0;
		height: 0px;
		right: 70px; } }
@keyframes enter-form-languages {
	from {
		opacity: 0;
		height: 0px;
		right: 70px; }
	to {
		opacity: 1;
		height: 160px;
		right: 0px; } }
@keyframes leave-animation {
	from {
		opacity: 1;
		height: 25px;
		right: 0px; }
	to {
		opacity: 0;
		height: 0px;
		right: 70px; } }
.animate-enter.ng-hide {
	height: 0px;
	opacity: 0;
	padding: 0; }
@keyframes enter {
	from {
		opacity: 0;
		height: 0px;
		right: -70px;
		padding: 0; }
	50% {
		right: 15px; }
	to {
		opacity: 1;
		height: auto;
		right: 0px; } }
.box-info {
	background-color: #ecf1f3;
	border: 1px dashed #94a8ab;
	margin: 5px 0;
	padding: 10px; }
.container .alert {
	margin-top: 10px; }
@media (max-width: 991px) {
	.remember-check {
		min-height: auto !important; } }
.wrap-comment {
	word-break: break-all; }
.align-vertical-center {
	vertical-align: middle !important; }
.titulo-footer {
	font-weight: bold; }
.aviso_privacidad {
	&:hover, &:focus {
	 color: #f7971b; } }
.caption.post-content {
	background-color: #fff;
	opacity: 0.6;
	filter: alpha(opacity=60); }
.caption.post-content h3 {
	&:hover, &:focus {
	 color: #c02216 !important; } }
.caption.post-content h3 {
	font-weight: bold; }
.product-category--img img {
	object-fit: scale-down !important; }
