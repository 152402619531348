.btn {
	border-top: 1px solid rgba(255,255,255, .2);
	border-left: 1px solid rgba(0,0,0, .075);
	border-right: 1px solid rgba(0,0,0, .075);
	border-bottom: 1px solid rgba(0,0,0, .075);
	box-shadow: 0 2px .25px rgba(0,0,0, .15);
	font-weight: 700;
	transition: all .2s ease; }
.btn-primary {
	background-color: $clr-rojo;
	&:hover, &:focus {
		background-color: darken($clr-rojo, 5%);
		border: 1px solid rgba(0,0,0, .075);
		color: #eee; } }
.btn-amarillo {
	background-color: $clr-amarillo;
	color: #333;
	&:hover, &:focus {
		background-color: darken($clr-amarillo, 7%);
		color: #333; } }
.btn-default {
	border: 1px solid $clr-gris-borde;
	box-shadow: 0 2px .25px rgba(0,0,0, .05); }
.btn-gris-azul {
	background-color: $clr-gris-azul;
	color: #fff; }
.btn-gris-azul:hover, .btn-gris-azul:focus {
	border-color: rgba(0,0,0, .2);
	background-color: darken($clr-gris-azul, 10%);
	color: darken(#fff, 20%);
	box-shadow: 0 2px 1px rgba(0,0,0, .15)inset; }
.btn-blanco-outline {
	background: transparent;
	border: 1px solid #fff;
	box-shadow: 0 2px .25px rgba(0,0,0, .15);
	color: #fff;
	font-weight: 700;
	transition: all .3s ease;
	display: inline-block;
	margin-bottom: 0;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.6;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover, &:focus {
		background: #fff;
		color: $clr-rojo;
		text-decoration: none; } }
// .btn-rojo
// 	background-color: $clr-rojo
// 	color: #fff
// 	&:hover, &:focus
// 		border-color: rgba(0,0,0, .2)
// 		background-color: darken($clr-rojo, 10%)
// 		color: darken(#fff, 20%)
// 		box-shadow: 0 2px 1px rgba(0,0,0, .15)inset
.btn-phantom {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
	color: $clr-oxford;
	&:hover, &:focus {
		background-color: rgba(0,0,0, .05); } }
.btn-xs {
	padding: 1px 5px; }
.btn-mc {
	margin-top: 15px; }
